<template>
    <div>
        <ToolsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <h1>Installation Instructions</h1>

            <img :src="`${$cdiVars.BASE_URL}/images/v3/cdi_install_instructions.jpg`" class="img-responsive img_content_right">

            <p>Here you will find installation instructions for CDI's products. These pdf files are easily printable, so you can take a copy on the roof with you if you need one.</p>

            <ul class='list_content'>
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/Install_Curb_Adapter_Install_Instructions.pdf`" target='_install'>Curb Adapter</a>
                </li>
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/Install_Bolt_Together_KD.pdf`" target='_install'>Bolt Together Knock Downs</a>
                </li>
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/Install_Metal_Bldg_Pitched_Curb.pdf`" target='_install'>Metal Building Pitched Curbs</a>
                </li>
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/Install_Metal_Bldg_Pitched_Curb_Insulated_Panels.pdf`" target='_install'>Metal Building Curbs w/Insulated Panels</a>
                </li>
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/Install_Z_Strap.pdf`" target='_install'>Z-Strings</a>
                </li>
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/Install_Hold_Downs_Generic.pdf`" target='_install'>Hold Downs</a>
                </li>
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/Install_Hold_Downs_KMart.pdf`" target='_install'>Hold Downs (K-Mart)</a>
                </li>
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/Install_Hold_Downs_Target.pdf`" target='_install'>Hold Downs (Target)</a>
                </li>
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/cdi-iso-install-guide.pdf`" target='_install'>Vibration Isolation Curb</a>
                </li>
            </ul>
        </main>
    </div>
</template>

<script>
import ToolsNav from '@/components/content/ToolsNav.vue'
export default {
    name: "InstallInstructions",
    components: {
        ToolsNav,
    },
    created() {
        this.$appStore.setTitle(['CDI Curb Adapter Install Instructions']);
    },
}
</script>

<style scoped>

</style>